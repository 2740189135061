// const API_BASE_URL = "http://localhost:3001/";
const API_BASE_URL = "https://khhivt71c9.execute-api.us-east-1.amazonaws.com/Prod/";

class AIService {

  async startThread(): Promise<any> {
    const body = {
      Action: "createThread",
      ThreadId: "",
      Message: ""
    };

    return this.makeRequest(JSON.stringify(body));
  }

  async getThread(threadId: string): Promise<any> {
    const body = {
      Action: "getThread",
      ThreadId: threadId,
      Message: ""
    };

    return this.makeRequest(JSON.stringify(body));
  }

  async sendChat(threadId: string, message: string): Promise<any> {
    const body = {
      Action: "sendChat",
      ThreadId: threadId,
      Message: message
    };

    return this.makeRequest(JSON.stringify(body));
  }

  async startRun(threadId: string): Promise<any> {
    const body = {
      Action: "startRun",
      ThreadId: threadId,
      Message: ""
    };

    return this.makeRequest(JSON.stringify(body));
  }

  async makeRequest(body: string): Promise<any> {
    const response = await fetch(`${API_BASE_URL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body
    });

    const responseData = await response.json();
    return responseData;
  }
};
  
const service = new AIService();
export default service;